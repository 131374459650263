.Closing {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    height: auto;
    width: 80%;
    box-sizing: border-box;
}

.ImageContainer {
    width: 100%;
}

.ImageContainer img {
    height: 100%;
    width: 100%;
}

.Details {
    min-width: 350px;
    padding: 1em 1em 0 2em;
    box-sizing: border-box;
}

.Details div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    line-height: 2em;
}

.Details div b {
    margin-right: 1em;
}

@media screen and (max-width: 767px) {
    .Closing {
        width: 100%;
    }
    .Details {
        min-width: 250px;
        padding: 1em 0 0 1em;
    }
}

@media screen and (max-width: 600px) {
    .Details {
        min-width: 230px;
    }
}

@media screen and (max-width: 400px) {
    .Details {
        width: 100%;
        min-width: unset;
        padding: 1em 1em 0 1em;
    }
}
