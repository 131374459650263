.StyledLink {
    box-sizing: border-box;
    border-radius: 10px;
    cursor: pointer;
    -moz-box-shadow: 1px 1px 1px rgba(56, 56, 56, 1), -1px 1px 1px rgba(56, 56, 56, 1);
    -webkit-box-shadow: 1px 1px 1px rgba(56, 56, 56, 1), -1px 1px 1px rgba(56, 56, 56, 1);
    box-shadow: 1px 1px 1px rgba(56, 56, 56, 1), -1px 1px 1px rgba(56, 56, 56, 1);
}

.StyledLink a {
    padding: 6px 12px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-family: Arial;
    font-size: 1em;
    font-weight: bold;
    letter-spacing: 1px;
    word-spacing: 1px;
    text-decoration: none;
    color: #000000;
    background-color: #B09032;
    border-radius: 10px;
}

.StyledLink:hover,
.StyledLink:focus {
    transform: translateY(-1px);
    -moz-box-shadow: 2px 2px 4px rgba(86, 86, 86, 1), -2px 2px 4px rgba(86, 86, 86, 1);
    -webkit-box-shadow: 2px 2px 4px rgba(86, 86, 86, 1), -2px 2px 4px rgba(86, 86, 86, 1);
    box-shadow: 2px 2px 4px rgba(86, 86, 86, 1), -2px 2px 4px rgba(86, 86, 86, 1);
}

.StyledLink a:hover,
.StyledLink a:focus {
    background-color: #D0B052;
}

.StyledLink a:active {
    background-color: #E0C062;
}

@media screen and (max-width: 767px) {
    .StyledLink {
        border-radius: 8px;
    }

    .StyledLink a {
        padding: 4px 8px;
        border-radius: 8px;
    }
}
