.SideDrawer {
    height: calc(100% - 40px);
    width: 200px;
    position: fixed;
    top: 40px;
    right: 0;
    box-sizing: border-box;
    border-left: 2px solid #B09032;
    -moz-box-shadow: -3px 0 6px -2px rgb(0, 0, 0, 0.6);
    -webkit-box-shadow: -3px 0 6px -2px rgb(0, 0, 0, 0.6);
    box-shadow: -3px 0 6px -2px rgb(0, 0, 0, 0.6);
    background-color: #04386D;
    z-index: 300;
}

.TopPanel {
    height: 100px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    padding-right: 30px;
    box-sizing: border-box;
}

.CloseButton {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0;
    box-sizing: border-box;
	font-size: 1.5em;
    line-height: 1;
    font-weight: bold;
	color: #FFFFFF;
    background-color: #04386D;
    border: none;
    cursor: pointer;
}

.CloseButton:hover,
.CloseButton:focus {
    color: #B09032;
}

.CloseButton:active {
    color: #E0C062;
}

.NavigationItems {
    width: 40%;
    position: absolute;
    left: 30%;
    margin-top: 30%;
    box-sizing: border-box;
}

.Hide {
    display: none;
}

.Open {
    transition: transform .3s ease-out;
    transition: transform .4s ease-out;
    transform: translateX(0);
}

.Closed {
    transition: transform .2s ease-in;
    transition: transform .3s ease-in;
    transform: translateX(204px);
}

.Show {
    display: block;
}

.Hide {
    display: none;
}

@media screen and (min-width: 768px) {
    .SideDrawer {
        display: none;
    }
}
