body {
  margin: 0;
  font-family: Georgia, Garamond, "Times New Roman", serif;
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
}

h1 {
    font-size: 32px;
    line-height: 40px;
}

h2 {
    font-size: 24px;
    line-height: 32px;
}

h3 {
    padding-top: 1em;
    margin-top: 0px;
    font-size: 20px;
}

h4 {
    font-size: 16px;
}

p {
    line-height: 1.4em;
    text-align: justify;
    text-justify: auto;
}

p, b, i {
    font-size: 16px;
}

ul, ol {
    margin: .75em 0 1.5em 0;
    padding: 0 5em 0 2em;
}

li {
    font-size: 16px;
}

li:not(:last-of-type) {
    margin-bottom: .75em;
}

@media screen and (max-width: 1100px) {

}

@media screen and (max-width: 767px) {
    body {
      font-size: 12px;
    }

    h1 {
        font-size: 24px;
        line-height: 32px;
    }

    h2 {
        font-size: 20px;
        line-height: 25px;
    }

    h3 {
        padding-top: 16px;
        font-size: 16px;
        line-height: 20px;
    }

    h4 {
        font-size: 12px;
        line-height: 16px;
    }

    p, b, i {
        font-size: 12px;
    }

    ul, ol {
        margin: .5em 0 1em 0;
        padding: 0 1em 0 2em;
    }

    li {
        font-size: 12px;
    }
}

@media screen and (max-width: 400px) {
    p {
        line-height: 1.5em;
        text-align: initial;
        text-justify: none;
    }
}
