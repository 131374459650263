.TestimonialsCarouselWrapper {
    width: 100%;
    border-top: 2px solid #000;
}

.TestimonialsCarousel {
    height: auto;
    max-width: 800px;
    margin: auto;
    padding: 2em 0 2em 0;
    box-sizing: border-box;
}

.TestimonialsCarouselLeadText {
    display: block;
    margin: 0 0 1em 0;
    box-sizing: border-box;
    font-size: 1.5em;
}

.TestimonialWrapper {
    height: auto;
    padding: 2em 0 3em 2em;
    box-sizing: border-box;
}

.FadeText {
  animation: fadetextin 2s;
}

.TestimonialIndicators {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0;
    margin: 0;
    margin: 0 0 4em 0 ;
}

.TestimonialIndicators li {
    margin: 0;
    padding: 0;
    list-style: none;
}

.TestimonialIndicators button {
    display: block;
    padding: 10px;
    margin: 0;
    box-sizing: border-box;
    border: 2px solid #000;
    border-radius: 50%;
    -moz-box-shadow: 0 0 2px 1px rgba(116, 116, 116, 1);
    -webkit-box-shadow: 0 0 2px 1px rgba(116, 116, 116, 1);
    box-shadow: 0 0 2px 1px rgba(116, 116, 116, 1);
    cursor: pointer;
}

.TestimonialIndicators button:hover,
.TestimonialIndicators button:focus {
    animation: fadein 0s forwards;
    -moz-animation: fadein 0s forwards;
    -webkit-animation: fadein 0s forwards;
    -o-animation: fadein 0s forwards;
    -moz-box-shadow: 0 0 3px 2px rgba(116, 116, 116, 1);
    -webkit-box-shadow: 0 0 3px 2px rgba(116, 116, 116, 1);
    box-shadow: 0 0 3px 2px rgba(116, 116, 116, 1);
}

.TestimonialIndicators button:active {
    background-color: #E0C062;
    animation: unset;
}

.TestimonialIndicators li:not(:last-of-type) {
    margin-right: 5em;
}

.ActiveSlideIndicator {
    animation: fadein 1s forwards;
    -moz-animation: fadein 1s forwards;
    -webkit-animation: fadein 1s forwards;
    -o-animation: fadein 1s forwards;
}

.InactiveSlideIndicator {
    animation: fadeout 1s forwards;
    -moz-animation: fadeout 1s forwards;
    -webkit-animation: fadeout 1s forwards;
    -o-animation: fadeout 1s forwards;
}

.MoreTestimonialsLinkContainer {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    padding-right: 2em;
}

@keyframes fadetextin {
    from {
        opacity: 1;
    }
    50% {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@-moz-keyframes fadetextin {
    from {
        opacity: 1;
    }
    50% {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@-webkit-keyframes fadetextin {
    from {
        opacity: 1;
    }
    50% {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@-o-keyframes fadetextin {
    from {
        opacity: 1;
    }
    50% {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes fadeout {
    from {
        background-color: #D0B052;
    }
    to {
        background-color: #B09032;
    }
}
@-moz-keyframes fadeout {
    from {
        background-color: #D0B052;
    }
    to {
        background-color: #B09032;
    }
}
@-webkit-keyframes fadeout {
    from {
        background-color: #D0B052;
    }
    to {
        background-color: #B09032;
    }
}
@-o-keyframes fadeout {
    from {
        background-color: #D0B052;
    }
    to {
        background-color: #B09032;
    }
}

@keyframes fadein {
    from {
        background-color: #B09032;
    }
    to {
        background-color: #D0B052;
    }
}
@-moz-keyframes fadein {
    from {
        background-color: #B09032;
    }
    to {
        background-color: #D0B052;
    }
}
@-webkit-keyframes fadein {
    from {
        background-color: #B09032;
    }
    to {
        background-color: #D0B052;
    }
}
@-o-keyframes fadein {
    from {
        background-color: #B09032;
    }
    to {
        background-color: #D0B052;
    }
}

@media screen and (max-width: 1100px) {
    .TestimonialsCarousel {
        padding: 2em 5em 2em 2em;
    }
}

@media screen and (max-width: 600px) {
    .TestimonialsCarousel {
        padding: 2em 4em 2em 1em;
    }

    .TestimonialWrapper {
        padding: 1em 0 1em 1em;
        height: 200px;
    }

    .TestimonialIndicators {
        margin: 0 0 4em 0 ;
    }

    .TestimonialIndicators button {
        padding: 8px;
        border-width: 1px;
    }
}
