.NotFound {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    padding: 1em 3em 2em 3em;
    box-sizing: border-box;
}

.NotFound h1 {
    outline-style: none;
}

.ImageWrapper {
	width: 500px;
	position: relative;
}

.ImageWrapper img {
    width: 100%;
}

.ImageSmoothEdge {
	width: 100%;
	position: absolute;
	bottom: 0px;
	top: 0px;
	box-shadow: inset 0px 0px 20px 30px white;
}

.LeadText {
    height: 100%;
    max-width: 800px;
    margin-bottom: 2.5em;
    box-sizing: border-box;
}

.LeadText h1 {
    margin-bottom: 1em;
    font-family: Arial;
}

.LeadText span {
    font-size: 1.5em;
    font-weight: bold;
    line-height: 1.75;
    color: #04386D;
}

.HelpfulLinksSection {
    max-width: 800px;
    box-sizing: border-box;
    font-size: 1.25em;
}

.HelpfulLinks {
    margin-top: 1em;
    list-style: none;
}

.HelpfulLinks li {
    font-size: .8em;
    font-style: italic;
}

.HelpfulLinks li:not(:last-of-type) {
    margin: 0 0 20px 0;
}

.HelpfulLinks a {
    font-weight: bold;
    color: #0000B0;
}

.HelpfulLinks a:hover,
.HelpfulLinks a:focus {
    color: #8000C0;
}

.HelpfulLinks a:active {
    color: #9800E0;
}

@media screen and (max-width: 767px) {
    .NotFound {
        padding: 1em 3em 1em 2em;
    }

    .ImageWrapper {
        width: 100%;
    }

    .ImageWrapper img {
        width: 100%;
    }

    .LeadText {
        min-width: unset;
        width: 100%;
    }

    .LeadText h1 {
        margin-bottom: .5em;
    }

    .HelpfulLinks {
        margin-top: 1em;
        padding: 0 0 0 16px;
    }

    .HelpfulLinks li:not(:last-of-type) {
        margin: 0 0 12px 0;
    }
}

@media screen and (max-width: 600px) {
    .NotFound {
        padding: 1em 3em 1em 1em;
    }
}
