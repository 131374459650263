.ContactForm {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 0;
    box-sizing: border-box;
    /* background: linear-gradient(rgba(0, 0, 0, 0.35), rgba(0, 0, 0, 0.35)), url('../../assets/images/other/coffee-computer.jpg'); */
    background-repeat: no-repeat;
    background-size: cover;
    background-position: right center;
}

.ContactForm:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: linear-gradient(rgba(0, 0, 0, 0.35), rgba(0, 0, 0, 0.35));
}

.FormSubmissionStatus {
    width: 400px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 1.5em;
    box-sizing: border-box;
    border-radius: 5%;
    background-color: #04386D;
    -moz-box-shadow: 1px -1px 2px 1px rgba(36, 36, 36, 1), -1px 1px 2px 1px rgba(36, 36, 36, 1);
    -webkit-box-shadow: 1px -1px 2px 1px rgba(36, 36, 36, 1), -1px 1px 2px 1px rgba(36, 36, 36, 1);
    box-shadow: 1px -1px 2px 1px rgba(36, 36, 36, 1), -1px 1px 2px 1px rgba(36, 36, 36, 1);
    outline: none;
    /* opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0); */
    -webkit-animation-name: fadeIn;
    -webkit-animation-duration: 1400ms;
    -webkit-animation-timing-function: ease-out;
    -webkit-animation-iteration-count: 1;
    -webkit-animation-fill-mode: forwards;
    animation: fadeIn .3s ease-in;
    -webkit-animation: fadeIn .3s ease-in;
    -moz-animation: fadeIn .3s ease-in;
    -o-animation: fadeIn .3s ease-in;
    -ms-animation: fadeIn .3s ease-in;
}

.FormSubmissionStatus:focus {
    -moz-box-shadow: 1px -1px 2px 2px rgba(36, 36, 36, 1), -1px 1px 2px 2px rgba(36, 36, 36, 1);
    -webkit-box-shadow: 1px -1px 2px 2px rgba(36, 36, 36, 1), -1px 1px 2px 2px rgba(36, 36, 36, 1);
    box-shadow: 1px -1px 2px 2px rgba(36, 36, 36, 1), -1px 1px 2px 2px rgba(36, 36, 36, 1);
}

.MessageContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.FormSubmissionStatusIcon {
    height: 50px;
    padding: 0;
    margin: 0;
}

.FormSubmissionStatus .Message {
    display: block;
    box-sizing: border-box;
    padding: 1em;
    margin: 1em 0 3em 0;
    font-size: 1em;
    line-height: 1.5;
    color: #FFFFFF;
}

.CloseButton {
    align-self: flex-end;
    padding: 0;
    margin: 0 0 1.5em 0;
	font-size: 1.25em;
    font-weight: 900;
	color: #B09032;
    background-color: transparent;
    border: none;
    cursor: pointer;
}

.CloseButton:hover, .CloseButton:focus {
	color: #D0B052;
}

.CloseButton:active {
	color: #E0C062;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@-moz-keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@-webkit-keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@-o-keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@-ms-keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.ContactForm h3 {
    position: relative;
    margin-bottom: 1em;
    margin-left: 1em;
    font-size: 2em;
    letter-spacing: 1px;
    word-spacing: 4px;
}

.FormDescription {
    max-width: 600px;
    margin-bottom: 4em;
    margin-left: 3em;
    font-size: 1.3em;
    font-weight: bold;
    font-style: italic;
}

form {
    width: 90%;
    max-width: 1000px;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: auto;
}

.Inputs {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    justify-content: space-between;
    flex-wrap: wrap;
}

.Inputs > *:not(:last-child) {
    width: 40%;
    min-width: 400px;
    margin: 0 2em 3em;
}

.Inputs > *:last-child {
    width: 40%;
    min-width: 500px;
    max-width: 100%;
    margin: 0 2em 0;
}

.ButtonContainer {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin: 48px auto;
    margin: 3em auto 3em 4em;
}

.ButtonContainer button:not(:last-of-type) {
    margin: 0 5em 0 0;
}

@media screen and (max-width: 767px) {
    .ContactForm h3 {
        margin-bottom: 1em;
        margin-left: 0;
    }

    .FormDescription {
        margin-bottom: 3em;
        margin-left: 0;
    }

    form {
        width: 75%;
        max-width: 500px;
    }

    .Inputs > *:not(:last-child) {
        width: 100%;
        min-width: unset;
        max-width: 350px;
        margin: 0 0 3.5em;
    }

    .Inputs > *:last-child {
        width: 100%;
        min-width: inherit;
        margin: 0;
    }

    .FormSubmissionStatus {
        width: 300px;
    }

    .FormSubmissionStatusIcon {
        height: 30px;
    }

    .FormSubmissionStatus .Message {
        margin: 1em 0 2em 0;
    }

    .ButtonContainer {
        justify-content: space-around;
        width: 100%;
        align-items: center;
        margin: 3em auto 3em 0;
    }

    .ButtonContainer button:not(:last-of-type) {
        margin: 0;
    }
}
