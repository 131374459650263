.Hero {
    height: 800px;
    width: 100%;
    box-sizing: border-box;
    position: relative;
    border-bottom: 2px solid #000000;
}

.Hero img {
    height: 100%;
    width: 100%;
    object-fit: fill;
}

.Overlay  {
    position: absolute;
    margin: 0;
    font-family: Arial;
    font-size: 1.9em;
    font-weight: bold;
    font-style: italic;
    letter-spacing: 1px;
}

.TopLeftPos {
    top: 1em;
    left: 1.5em;
}

.BottomLeftPos {
    bottom: 1em;
    left: 1.5em;
}

.TopRightPos {
    top: 1em;
    right: 1.5em;
}

.BottomRightPos {
    bottom: 1em;
    right: 1.5em;
}

@media screen and (max-width: 1800px) {
    .Hero {
        height: 700px;
    }
}

@media screen and (max-width: 1600px) {
    .Hero {
        height: 600px;
    }
}

@media screen and (max-width: 1100px) {
    .Hero {
        height: 500px;
    }
}

@media screen and (max-width: 900px) {
    .Hero {
        height: 400px;
    }

    .Overlay {
        font-size: 1.5em;
    }

    .TopLeftPos {
        top: .75em;
        left: 1.25em;
    }

    .BottomLeftPos {
        bottom: .75em;
        left: 1.25em;
    }

    .TopRightPos {
        top: .75em;
        right: 1.25em;
    }

    .BottomRightPos {
        bottom: .75em;
        right: 1.25em;
    }
}

@media screen and (max-width: 600px) {
    .Hero {
        height: 300px;
    }

    .TopLeftPos {
        top: .5em;
        left: 1em;
    }

    .BottomLeftPos {
        bottom: .5em;
        left: 1em;
    }

    .TopRightPos {
        top: .5em;
        right: 1em;
    }

    .BottomRightPos {
        bottom: .5em;
        right: 1em;
    }
}

@media screen and (max-width: 400px) {
    .Hero {
        height: 200px;
    }
}
