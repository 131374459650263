.SideNavPanel {
    height: 100vh;
    width: 400px;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    padding: 6em 1em 2em 2em;
    box-sizing: border-box;
    background-color: #04386D;
}

.SideNavHeading {
    display: block;
    font-size: 1.5em;
    font-weight: bold;
    font-style: italic;
    color: #FFFFFF;
}

.SideNavItems {
    margin: 3em 0 0 0;
    padding: 0;
    box-sizing: border-box;
    list-style: none;
}

.SideNavWrapperItem:not(:last-of-type) {
    margin: 0 0 3em 0;
}

.MobileToggle {
    display: none;
}

@media screen and (max-width: 1100px) {
    .SideNavPanel {
        width: auto;
        position: fixed;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-start;
        padding: 0;
        background-color: transparent;
        z-index: 200;
    }

    .SideNavWrapper {
        height: calc(100vh - 1em);
        width: 350px;
        position: relative;
        top: .5em;
        padding: 5em 2em 1em 2em;
        box-sizing: border-box;
        border-radius: 0 10px 10px 0;
        background-color: #04386D;
        overflow-y: auto;
    }

    .SideNavWrapperItem:not(:last-of-type) {
        margin: 0 0 3em 0;
    }

    .MobileToggle {
        width: 30px;
        height: 120px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        align-self: flex-end;
        position: relative;
        bottom: 4em;
        left: 0;
        box-sizing: border-box;
        border: 1px solid #04386D;
        border-radius: 0 10px 10px 0;
        -moz-box-shadow: 0 -2px 2px -1px rgb(0, 0, 0, 0.6),
        0px 2px 2px -1px rgb(0, 0, 0, 0.6),
        2px 0px 2px -1px rgb(0, 0, 0, 0.6);
        -webkit-box-shadow: 0 -2px 2px -1px rgb(0, 0, 0, 0.6),
        0px 2px 2px -1px rgb(0, 0, 0, 0.6),
        2px 0px 2px -1px rgb(0, 0, 0, 0.6);
        box-shadow: 0 -2px 2px -1px rgb(0, 0, 0, 0.6),
        0px 2px 2px -1px rgb(0, 0, 0, 0.6),
        2px 0px 2px -1px rgb(0, 0, 0, 0.6);
        color: #000000;
        background-color: #B09032;
        cursor: pointer;
        outline-style: none;
    }

    .MobileToggle:hover,
    .MobileToggle:focus {
        background-color: #D0B052;
    }

    .MobileToggle:active {
        background-color: #E0C062;
    }

    .MobileToggleSpan {
        display: block;
        position: absolute;
        width: 120px;
        padding: 0;
        margin: 0;
        font-size: 16px;
        letter-spacing: 1px;
        transform: rotate(270deg);
    }

    .Closed {
        transform: translateX(-350px);
        transition: transform .4s ease-in;
    }

    .Open {
        transform: translateX(0);
        transition: transform .4s ease-out;
    }

    .Open .SideNavWrapper {
        -moz-box-shadow: 0 -2px 2px -1px rgb(0, 0, 0, 0.6),
        0px 2px 2px -1px rgb(0, 0, 0, 0.6),
        2px 0px 2px -1px rgb(0, 0, 0, 0.6);
        -webkit-box-shadow: 0 -2px 2px -1px rgb(0, 0, 0, 0.6),
        0px 2px 2px -1px rgb(0, 0, 0, 0.6),
        2px 0px 2px -1px rgb(0, 0, 0, 0.6);
        box-shadow: 0 -2px 2px -1px rgb(0, 0, 0, 0.6),
        0px 2px 2px -1px rgb(0, 0, 0, 0.6),
        2px 0px 2px -1px rgb(0, 0, 0, 0.6);
    }
}

@media screen and (max-width: 767px) {
    .SideNavWrapper {
        width: 250px;
        padding: 5em 2em 2em 2em;
    }

    .SideNavWrapperItem:not(:last-of-type) {
        margin: 0 0 4em 0;
    }

    .Closed {
        transform: translateX(calc(-250px));
    }

    .MobileToggle {
        outline-width: 0;
    }

    .MobileToggleSpan {
        font-size: 14px;
        line-height: 1em;
    }
}
