footer {
    height: auto;
    width: 100%;
    box-sizing: border-box;
    font-size: 1em;
    color: #fff;
    background-color: #04386D;
}

.MainFooter {
    padding: 3em 4em;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    outline-style: none;
}

.Mentions {
    padding: 2.5em 1.5em 1.5em 1.5em;
    box-sizing: border-box;
}

.OwnerMention {
    height: 2em;
    display: inline-block;
    margin-right: 1em;
}

.WebDesignerMention {
    height: 2em;
    display: inline-block;
}

.WebDesignerMention i {
    margin-right: .5em;
    box-sizing: border-box;
    flex-wrap: nowrap;
}

.WebDesignerMention img {
    height: 100%;
}

.WebDesignerMention a {
    margin-left: .5em;
    font-weight: bold;
    letter-spacing: 1px;
    word-spacing: 2px;
    color: #FFFFFF;
    text-decoration: none;
    text-underline-offset: 2px;
}

.WebDesignerMention a:hover,
.WebDesignerMention a:focus {
    color: #B09032;
    text-decoration: underline;
}

.WebDesignerMention a:active {
    color: #E0C062;
    text-decoration: underline;
}

.Company {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    flex-wrap: wrap;
}

.Company > *:first-child > div {
    margin-bottom: 3em;
}

.PhysicalAddress span {
    margin: 0;
    line-height: 2;
}

.PhysicalAddress {
    margin: 0;
}

.Realtors {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
    margin: 2em 4em 2em 5em;
}

.Realtor {
    display: flex;
    flex-direction: column;
    margin-bottom: 4em;
}

.Realtor:not(:last-of-type) {
    margin-right: 5em;
}

.Realtor > span {
    font-size: 1.25em;
    font-weight: bold;
    word-spacing: 3px;
    letter-spacing: 1px;
}

.ContactInfo {
    width: auto;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.CompanyContactInfo .ContactInfo:first-of-type,
.Realtor .ContactInfo:first-of-type {
    margin-top: 1em;
}

.CompanyContactInfo .ContactInfo:not(:first-of-type),
.Realtor .ContactInfo:not(:first-of-type) {
    margin-top: 1.75em;
}

.ContactInfo img {
    height: 1.25em;
    margin-right: 1em;
}

.ContactInfo a {
    max-height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-decoration: none;
    color: #FFFFFF;
}

.ContactInfo a:hover,
.ContactInfo a:focus {
    color: #B09032;
}

.ContactInfo a:active {
    color: #E0C062;
}

.OtherLinks {
    width: 100%;
    padding: 0;
    display: flex;
    flex-flow: row;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
}

.OtherLinks a {
    color: #FFFFFF;
    padding-bottom: 4px;
    box-sizing: border-box;
    text-decoration: none;
    background-size: 0% 2px;
    background-repeat: no-repeat;
    background-position: 50% calc(100%);
    background-image: linear-gradient(#B09032, #B09032);
    -webkit-transition: background-size .3s ease, color .3s ease;
    -moz-transition: background-size .3s ease, color .3s ease;
    -o-transition: background-size .3s ease, color .3s ease;
    transition: background-size .3s ease, color .3s ease;
}

.OtherLinks a:not(:first-of-type) {
    margin-left: 1.5em;
}

.OtherLinks a:not(:last-of-type) {
    margin-right: 1.5em;
}

.OtherLinks a:hover,
.OtherLinks a:focus {
    color: #B09032;
    background-size: 100% 2px;
}

.OtherLinks a:active {
    color: #E0C062;
    background-image: linear-gradient(#E0C062, #E0C062);
    background-size: 100% 2px;
    -moz-transition: unset;
    -o-transition: unset;
    transition: unset;
}

.OtherLinks a.active {
    color: #D0B052;
    background-image: linear-gradient(#D0B052, #D0B052);
    background-size: 100% 2px;
    -moz-transition: unset;
    -o-transition: unset;
    transition: unset;
}

@media screen and (max-width: 1100px) {
    footer > div:nth-of-type(2) {
        flex-direction: column;
    }

    .Company, .Realtors {
        margin-bottom: 2em;
    }

    .MainFooter {
        padding: 3em 0;
    }

    .Company {
        flex-direction: row;
    }

    .Company > *:not(:first-child) {
        margin-top: 0em;
        margin-left: 6em;
    }

    .Company > *:first-child > div {
        margin-bottom: 2em;
    }

    .Realtors {
        margin: 2em 4em 2em 4em;
    }
}

@media screen and (max-width: 600px) {
    .MainFooter {
        padding: 3em 3em;
    }

    .Company {
        flex-direction: column;
        align-items: center;
        margin-bottom: 3em;
    }

    .Company > *:not(:first-child) {
        margin: 1em 0 0 0;
    }

    .Realtors {
        flex-direction: column;
        margin: 2em 1em 0 1em;
    }

    .Realtor {
        margin: 0 0 4em 1em;
    }

    .Realtor:not(:last-of-type) {
        margin-right: 3em;
    }
}
