.AbsolutePositionModal {
    position: absolute;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100;
}

.AbsolutePositionModal[aria-hidden="true"] {
    display: none;
}

.Backdrop {
    width: 100%;
    height: 100%;
    z-index: 110;
    left: 0;
    top: 0;
    background-color: rgba(0, 0, 0, .3);
}

.Content {
    position: absolute;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    z-index: 120;
}
