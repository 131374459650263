.SkipLinks {
    height: 60px;
    width: 100vw;
    position: fixed;
    top: -60px;
    left: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    margin: 0;
    padding: 0 2em 0 2em;
    box-sizing: border-box;
    list-style: none;
    border-bottom: 2px solid #555555;
    background-color: #FFFFFF;
    z-index: 400;
}

.SkipLinks:focus {
    outline-style: none;
}

.Show {
    top: 0;
}

.Backdrop {
    position: fixed;
    top: 60px;
    left: 0;
    height: calc(100vh - 60px);
    width: 100vw;
    background-color: rgba(0, 0, 0, 0.2);
    z-index: 500;
}

.SkipLinks li {
    margin: 0;
}
.SkipLinks li:not(:last-of-type) {
    margin-right: 3em;
}

.SkipLinks a {
    padding: .25em .5em;
    box-sizing: border-box;
    border: 2px solid #0000B0;
    border-radius: 5px;
    font-size: 1em;
    color: #0000B0;
    cursor: pointer;
}

.SkipLinks a:focus,
.SkipLinks a:hover {
    border-color: #8000C0;
    color: #8000C0;
}

@media screen and (max-width: 767px) {
    .SkipLinks {
        height: 40px;
    }

    .Backdrop {
        top: 40px;
        height: calc(100vh - 40px);
    }
}
