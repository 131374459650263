.ContactUsButton {
    width: 120px;
    height: 30px;
    position: fixed;
    bottom: 150px;
    right: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 1px solid #000;
    border-bottom: unset;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    box-sizing: border-box;
    font-size: 1em;
    letter-spacing: 1px;
    color: #000;
    background-color: #B09032;
    -moz-box-shadow: 0 -2px 2px -1px rgb(0, 0, 0, 0.6),
    2px 0px 2px -1px rgb(0, 0, 0, 0.6),
    -2px 0px 2px -1px rgb(0, 0, 0, 0.6);
    -webkit-box-shadow: 0 -2px 2px -1px rgb(0, 0, 0, 0.6),
    2px 0px 2px -1px rgb(0, 0, 0, 0.6),
    -2px 0px 2px -1px rgb(0, 0, 0, 0.6);
    box-shadow: 0 -2px 2px -1px rgb(0, 0, 0, 0.6),
    2px 0px 2px -1px rgb(0, 0, 0, 0.6),
    -2px 0px 2px -1px rgb(0, 0, 0, 0.6);
    cursor: pointer;
    z-index: 150;
    -moz-transform: rotate(270deg);
    -webkit-transform: rotate(270deg);
    transform: rotate(270deg);
    -moz-transform-origin: bottom right;
    -webkit-transform-origin: bottom right;
    transform-origin: bottom right;
    animation: blinkingText infinite 3s linear;
}

.ContactUsButton:hover,
.ContactUsButton:focus {
    background-color: #D0B052;
    animation: none;
}

.ContactUsButton:active {
    background-color: #E0C062;
    animation: none;
}

@keyframes blinkingText {
    0% {
        -moz-filter: brightness(1);
        -webkit-filter: brightness(1);
        filter: brightness(1);
    }
    25% {
        -moz-filter: brightness(1.1);
        -webkit-filter: brightness(1.1);
        filter: brightness(1.1);
    }
    50% {
        -moz-filter: brightness(1.2);
        -webkit-filter: brightness(1.2);
        filter: brightness(1.2);
    }
    75% {
        -moz-filter: brightness(1.1);
        -webkit-filter: brightness(1.1);
        filter: brightness(1.1);
    }
    100% {
        -moz-filter: brightness(1);
        -webkit-filter: brightness(1);
        filter: brightness(1);
    }
}

@media screen and (max-width: 767px) {
    .ContactUsButton {
        width: 120px;
        bottom: 250px;
        font-size: 1em;
        font-size: 14px;
    }
}

@media screen and (max-width: 600px) {
    .ContactUsButton {
        bottom: 250px;
    }
}
