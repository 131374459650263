.SoldSlider {
    height: 550px;
    width: 100%;
}

.Home {
    padding: 1em 3em 2em 3em;
}

.MyHomeIQContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    margin: 2.5em 0;
}

.MyHomeIQContainer h2 {
    color: #04386D;
}

.MyHomeIQContainer p {
    font-size: 1.1em;
}

.MyHomeIQContainer iframe {
    display: block;
    margin: 0;
    border: none;
}

.MiddleContent {
    display: grid;
    grid-template-areas:
    'realty-reqs about';
    grid-template-columns: 3fr 4fr;
    grid-gap: 2em;
}

.RealtyReqs {
    grid-area: realty-reqs;
    margin: 0 1em 1em 1em;
    font-size: 1.2em;
    font-style: italic;
}

.RealtyReqs > span:first-of-type {
    margin: 0 2em 1.5em 0;
    display: block;
}

.ContactUs {
    margin-top: 1em;
    font-weight: 600;
    letter-spacing: 1px;
    box-sizing: border-box;
}

.ContactUs span {
    display: block;
    margin-bottom: 1.5em;
    line-height: 1.5;
}

.RightMargin {
    margin-right: .5em;
}

.ContactUs a {
    display: inline-block;
    padding: 0;
    font-family: Georgia, Garamond, "Times New Roman", serif;
    font-weight: 600;
    color: #0000B0;
    text-decoration: none;
    border: none;
    cursor: pointer;
}

.ContactUs a:hover,
.ContactUs a:focus {
    color: #8000C0;
}

.ContactUs a:active {
    color: #9800E0;
}

.ContactUs button {
    font-size: 1em;
}

.About {
    grid-area: about;
    display: inline-flex;
}

.Company {
    padding: 2em 2.5em 1em 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.Company p {
    line-height: 1.75;
}

.Realtor:not(:last-of-type) {
    margin-bottom: 4em;
}

.PhotoContainer {
    width: 200px;
}

.PhotoContainer img {
    height: 100%;
    width: 100%;
}

.Realtor span {
    display: block;
    margin-top: .5em;
    font-size: 1em;
    font-weight: 700;
    line-height: 1.25em;
}

.LinkContainer {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    flex-wrap: wrap;
    margin-top: 1em;
}

.LinkContainer > * {
    margin-bottom: 2.5em;
}

.LinkContainer > *:not(:last-child) {
    margin-right: 2em;
}

@media screen and (max-width: 1100px) {
    .SoldSlider {
        height: 450px;
    }

    .MyHomeIQContainer {
        margin: 1.5em 0;
    }

    .MiddleContent {
        grid-template-areas:
        'realty-reqs'
        'about';
        grid-template-columns: 1fr;
        grid-gap: 1em;
    }

    .RealtyReqs {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        margin: 1em 0 .5em 1em;
    }

    .Company {
        padding: 0 4em 1em 0;
    }

    .PhotoContainer {
        width: 180px;
    }
}

@media screen and (max-width: 900px) {
    .SoldSlider {
        height: 350px;
    }
}

@media screen and (max-width: 767px) {
    .SoldSlider {
        height: 300px;
    }

    .MyHomeIQContainer {
        margin: 1em 0;
    }

    .Home {
        padding: 1em 3em 1em 2em;
    }

    .MiddleContent {
        grid-template-areas:    'realty-reqs'
                                'about';
        grid-template-columns: auto;
        grid-template-rows: auto;
        grid-gap: 1em;
    }

    .RealtyReqs {
        margin: 0 0 0 0;
    }

    .ContactUs {
        font-size: 1em;
        line-height: 1.25em;
    }

    .ContactUs span {
        margin-bottom: 1em;
    }

    .Company {
        padding: 0 4em 0 0;
    }

    .Realtor:not(:last-of-type) {
        margin-bottom: 5em;
    }

    .PhotoContainer {
        width: 160px;
    }

    .LinkContainer {
        margin-top: 1em;
    }
}

@media screen and (max-width: 600px) {
    .SoldSlider {
        height: 250px;
    }

    .Home {
        padding: 1em 3em 1em 1em;
    }

    .Company {
        padding: 0 2.5em 0 0em;
    }

    .Realtor:not(:last-of-type) {
        margin-bottom: 8em;
    }
}

@media screen and (max-width: 500px) {
    .SoldSlider {
        height: 200px;
    }

    .Home {
        padding: 1em 1.5em 1em 1em;
    }

    .PhotoContainer {
        width: 120px;
    }
}

@media screen and (max-width: 400px) {
    .SoldSlider {
        height: 175px;
    }

    .PhotoContainer {
        width: 100px;
    }
}
