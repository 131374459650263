.ImageGallery {
    display: flex;
    flex-direction: row;
}

.ImageGallery img {
    height: 40px;
}

.ImageGallery img:not(:last-of-type) {
    margin-right: 3em;
}

@media screen and (max-width: 767px) {
    .ImageGallery img {
        height: 30px;
    }
}
