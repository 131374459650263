.NavigationItems {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 0;
    padding: 0;
    list-style: none;
}

@media screen and (max-width: 1100px) {
    .NavigationItems {
        flex-direction: row;
        justify-content: center;
        box-sizing: border-box;
    }
}
