.Logo {
    box-sizing: border-box;
}

.Small {
    height: 30px;
}

.Large {
    height: 40px;
}

.Logo a {
    height: 100%;
    text-decoration: none;
    display: flex;

}

.Logo img {
    height: 100%;
}

.Logo img:hover {
    cursor: pointer;
}

@media screen and (max-width: 767px) {
    .Small {
        height: 20px;
    }

    .Large {
        height: 30px;
    }
}
