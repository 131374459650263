.PrivacyPolicy {
    max-width: 1000px;
    padding: 1em 3em 2em 3em;
    box-sizing: border-box;
}

.PrivacyPolicy h2 {
    margin-top: 2em;
}

.PrivacyPolicy a {
    word-wrap: break-word;
    overflow: hidden;
    color: #0000B0;
}

.PrivacyPolicy a:hover,
.PrivacyPolicy a:focus {
    color: #8000C0;
}

.PrivacyPolicy a:active {
    color: #9800E0;
}

.DisplayBlock {
    display: block;
}

@media screen and (max-width: 767px) {
    .PrivacyPolicy {
        padding: 1em 3em 1em 2em;
    }
}

@media screen and (max-width: 600px) {
    .PrivacyPolicy {
        padding: 1em 3em 1em 1em;
    }

    .PrivacyPolicy h2 {
        margin-top: 1.5em;
    }
}
