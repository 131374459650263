.MainContent {
    margin-top: 60px;
    box-sizing: border-box;
    outline-style: none;
    -ms-transition: margin-top .7s ease;
    -o-transition: margin-top .7s ease;
    -moz-transition: margin-top .7s ease;
    -webkit-transition: margin-top .7s ease;
    transition: margin-top .7s ease;
}

.PushDown {
    margin-top: 120px;
}

@media screen and (max-width: 767px) {
    .MainContent {
        margin-top: 40px;
    }

    .PushDown {
        margin-top: 80px;
    }
}
