.TeamCard {
    max-width: 1000px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    padding: 2em 0 1em 0;
    margin: 0 1em 0 2em;
    box-sizing: border-box;
}

.TeamCard:not(:last-of-type) {
    border-bottom: 1px solid #555555;
}

.MemberPhoto {
    width: 200px;
    margin-right: 3em;
}

.MemberDetails {
    display: flex;
    flex-direction: column;
    padding: 1em 1em 0 1em;
    box-sizing: border-box;
}

.Single,
.Multiple {
    font-size: 1.2em;
}

.Multiple {
    display: flex;
    flex-direction: column;
    margin-bottom: 12px;
}

.Multiple *:not(:last-of-type) {
    margin-right: 8px;
}

.Single {
    display: flex;
    flex-direction: column;
}

.Single *:not(:last-of-type) {
    margin-bottom: 10px;
}

.BoldText {
    display: block;
    font-weight: 700;
}

.BoldItalicText {
    display: block;
    font-weight: 700;
    font-style: italic;
}

.MemberDetails p {
    min-height: 60px;
    line-height: 1.5em;
}

.MemberContacts {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding-top: 16px;
}

.MemberContactInfo {
    display: flex;
    flex-direction: row;
}

.MemberContactInfo:not(:last-of-type) {
    margin-right: 100px;
}

.MemberContactInfo {
    margin-bottom: 2em;
}

.MemberContactInfo img {
    height: 20px;
    margin-right: 1em;
}

.MemberContactInfo a {
    font-weight: 600;
    color: #0000B0;
    text-decoration: none;
}

.MemberContactInfo a:hover,
.MemberContactInfo a:focus {
    color: #8000C0;
}

.MemberContactInfo a:active{
    color: #9800E0;
}

@media screen and (max-width: 1000px) {
    .MemberPhoto {
        margin-right: 1em;
    }
}

@media screen and (max-width: 600px) {
    .TeamCard {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
    }

    .MemberPhoto {
        margin-right: 0;
        margin-bottom: 1em;
    }

    .MemberContacts {
        padding-top: 12px;
    }

    .MemberContactInfo:not(:last-of-type) {
        margin-bottom: 24px;
    }

    .Multiple {
        flex-direction: column;
    }

    .Multiple span:last-of-type {
        line-height: 24px;
    }
}
