.Header {
    height: 60px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    padding: 0px 1em;
    box-sizing: border-box;
    -webkit-box-shadow: 0px 2px 2px rgb(0, 0, 0, 0.6);
    -moz-box-shadow: 0px 2px 2px rgb(0, 0, 0, 0.6);
    box-shadow: 0px 2px 2px rgb(0, 0, 0, 0.6);


    /* -webkit-box-shadow: 0px 2px 2px red;
    -moz-box-shadow: 0px 2px 2px red;
    box-shadow: 0px 2px 2px red; */


    background-color: #04386D;
    z-index: 300;
    -ms-transition: top .7s ease;
    -o-transition: top .7s ease;
    -moz-transition: top .7s ease;
    -webkit-transition: top .7s ease;
    transition: top .7s ease;
}

.PushDown {
    top: 60px;
}

.Hide {
    top: -64px;
}

.PhoneContact {
    font-size: 1.2em;
    color: #FFFFFF;
    letter-spacing: 1px;
    text-decoration: none;
}

.PhoneContact:hover,
.PhoneContact:focus {
    color: #B09032;
}

.PhoneContact:active {
    color: #E0C062;
}

.HamburgerMenu {
    display: none;
}

.DesktopOnly {
    display: block;
}

@media screen and (max-width: 1100px) {
    .PhoneContact {
        display: none;
    }
}

@media screen and (max-width: 767px) {
    .Header {
        height: 40px;
    }

    .PushDown {
        top: 40px;
    }

    .Hide {
        top: -44px;
    }

    .HamburgerMenu {
        display: block;
    }

    .DesktopOnly {
        display: none;
    }
}
