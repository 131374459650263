.ImageSlider {
    height: 100%;
    width: 100%;
    position: relative;
    border-bottom: 2px solid #000;
    box-sizing: border-box;
}

.SlideContainer {
    -webkit-transition: opacity 3s ease-in-out;
    -moz-transition: opacity 3s ease-in-out;
    -o-transition: opacity 3s ease-in-out;
    transition: opacity 3s ease-in-out;
}

.ImageSlider img {
    width: 100%;
    height: 100%;
    position: absolute;
    object-fit: cover;
}

.FadeOut {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
}

.FadeIn {
    opacity: 1;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
    filter: alpha(opacity=1);
}

.Details {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    position: absolute;
    bottom: 1em;
    left: 1.5em;
    font-family: Arial;
    font-size: 1.5em;
    font-weight: 600;
    letter-spacing: 2px;
}

.Details span {
    display: inline-block;
    text-transform: none;
    -webkit-transition: color 3s ease-in-out, text-shadow 3s ease-in-out, opacity 3s ease-in-out;
    -moz-transition: color 3s ease-in-out, text-shadow 3s ease-in-out, opacity 3s ease-in-out;
    -o-transition: color 3s ease-in-out, text-shadow 3s ease-in-out, opacity 3s ease-in-out;
    transition: color 3s ease-in-out, text-shadow 3s ease-in-out, opacity 3s ease-in-out;
}

.Details > span:nth-of-type(3) {
    font-style: italic;
}

.Details span:not(:last-of-type) {
    margin-bottom: .5em;
}

.MoreClosingsLinkContainer {
    position: absolute;
    bottom: 1.5em;
    right: 2.5em;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}

@media screen and (max-width: 900px) {
    .Details {
        font-size: 1.3em;
    }
}

@media screen and (max-width: 600px) {
    .Details {
        bottom: .75em;
        left: 1em;
    }

    .Details span:not(:last-of-type) {
        margin-bottom: .3em;
    }

    .MoreClosingsLinkContainer {
        bottom: 1.25em;
        right: 1.5em;
    }
}

@media screen and (max-width: 400px) {
    .Details {
        bottom: .5em;
        left: .75em;
    }

    .Details span:not(:last-of-type) {
        margin-bottom: .3em;
    }

    .MoreClosingsLinkContainer {
        bottom: 1em;
        right: 1.25em;
    }
}
