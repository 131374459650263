.TextButton {
    padding: 0;
    margin: 0;
    font-family: Georgia, Garamond, "Times New Roman", serif;
    font-weight: bold;
    color: #0000B0;
    background-color: transparent;
    border: none;
    outline: none;
    cursor: pointer;
}

.TextButton:hover,
.TextButton:focus {
    color: #8000C0;
}

.TextButton:active {
    color: #9800E0;
}
