.Items {
    list-style: none;
    margin: 0;
    padding: 0;
}

.Items li {
    margin: 0;
    padding: 0;
    padding: 1em 0 4em 0;
}

.Items li:not(:last-of-type) {
    border-bottom: 1px solid #777777;
}

.PageButtons {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin: 1em 0 0 0;
    padding: .75em 0 .75em 2em;
    box-sizing: border-box;
    list-style: none;
    box-shadow: inset 0 0 4px 1px rgba(0, 0, 0, 0.3);
    background-color: #cccccc;
}

.PageButtons span {
    font-size: 1.1em;
    font-weight: 600;
    line-height: 1;
    padding: 0;
    margin-right: 40px;
}

.PageButton:not(:first-of-type) {
    margin-left: 40px;
}

.PageButton {
    font-size: 1.1em;
    font-weight: 600;
    line-height: 1;
    padding: 2px;
    box-sizing: border-box;
    color: #000000;
    background-color: transparent;
    border: none;
    border-bottom: 2px solid transparent;
    cursor: pointer;
}

.PageButton:focus,
.PageButton:hover {
    color: #0438BD;
}

.PageButton:active {
    color: #0478FF;
}

.SelectedPage {
    color: #0438BD;
    border-bottom: 2px solid #0438BD;
    cursor: default;
}

@media screen and (max-width: 767px) {
    .Items li {
        padding: 1em 0 3.5em 0;
    }

    .PageButtons {
        padding: .75em 0 .75em 1.5em;
        box-shadow: inset 0 0 3px 1px rgba(0, 0, 0, 0.3);
    }

    .PageButtons span {
        margin-right: 30px;
    }

    .PageButton {
        padding: 2px;
    }

    .PageButtons:not(:first-of-type) {
        margin-left: 32px;
    }
}
