.SiteMap {
    position: relative;
    padding: 1em 3em 2em 3em;
}

.SiteMap:after {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    content: "";
    background: rgb(26, 31, 41) url('../../assets/images/other/sitemap.jpg') no-repeat center;
    background-size: cover;
    filter: grayscale(90%);
    opacity: 0.70;
    z-index: -1;
}

.SiteMap > * {
    position: relative;
    z-index: 1;
}

.SiteMap h1 {
    text-align: center;
}

.SiteMapContent {
    max-width: 1200px;
    margin-top: 3em;
    box-sizing: border-box;
}

.SiteMapContent ul {
    padding: 0;
    margin: 1.5em 0 0 2em;
    box-sizing: border-box;
    list-style: none;
}

.SiteMapContent > .SiteMapList {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    justify-content: flex-start;
    flex-wrap: wrap;
    margin: 0;
}

.SiteMapContent > .SiteMapList > li {
    width: 12em;
    margin: 0 4em 8em 0;
}

.SiteMapContent li {
    display: inline-block;
    width: 100%;
    padding: 0;
    margin: .5em 0 .5em 0;
}

.SiteMapListItem pre {
    width: 100%;
    margin: 0;
	white-space: pre;
	white-space: pre-wrap;
	white-space: pre-line;
	white-space: -pre-wrap;
	white-space: -o-pre-wrap;
	white-space: -moz-pre-wrap;
	white-space: -hp-pre-wrap;
	word-wrap: break-word;
}

.SiteMapListItem a {
    padding: 0;
    margin: 0;
    font-family: Arial;
    font-weight: bold;
    color: #0000FF;
    text-decoration: none;
    text-underline-offset: 3px;
    letter-spacing: 1px;
}

.SiteMapContent > ul > li > pre > a {
    font-size: 1.6em;
    text-transform: uppercase;
}

.SiteMapContent > ul > li > ul > li > pre > a {
    font-size: 1.4em;
}

.SiteMapContent > ul> li > ul > li > ul > li > pre > a {
    font-size: 1.2em;
}

.SiteMapListItem a:hover {
    text-decoration: underline;
}

@media screen and (max-width: 1100px) {
    .SiteMapContent > .SiteMapList > li {
        margin: 0 6em 6em 0;
    }

    .SiteMapContent li {
        margin: .6em 0 .6em 0;
    }
}

@media screen and (max-width: 767px) {
    .SiteMap {
        padding: 1em 3em 1em 2em;
    }

    .SiteMapContent > .SiteMapList > li {
        width: 100%;
        margin: 0 0 3em 0;
    }
}

@media screen and (max-width: 600px) {
    .SiteMap {
        padding: 1em 3em 1em 1em;
    }
}
