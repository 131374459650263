.TermsOfService {
    max-width: 1100px;
    padding: 1em 3em 2em 3em;
    box-sizing: border-box;
}

.TermsOfService h1 {
    color: #04386D;
}

.TermsOfService h2 {
    margin-top: 2em;
    font-family: Arial;
}

.TermsOfService a {
    word-wrap: break-word;
    overflow: hidden;
    color: #0000B0;
}

.TermsOfService a:hover,
.TermsOfService a:focus {
    color: #8000C0;
}

.TermsOfService a:active {
    color: #9800E0;
}

.TermsOfService p:last-of-type {
    margin-bottom: 5em;
}

.ContactUsBlock {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin: 1em 0 1em 0;
    box-sizing: border-box;
}

.ContactUsBlock * {
    display: block;
    line-height: 1.5;
}

@media screen and (max-width: 767px) {
    .TermsOfService {
        padding: 1em 3em 1em 2em;
    }
}

@media screen and (max-width: 600px) {
    .TermsOfService {
        padding: 1em 3em 1em 1em;
    }
}
