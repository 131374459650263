.NavigationItem:not(:last-of-type) {
    margin-bottom: 3em;
}

.NavigationItem a {
    height: 100%;
    width: 100%;
    padding-bottom: 4px;
    box-sizing: border-box;
    text-decoration: none;
    font-size: 1em;
    color: #FFFFFF;
    background-size: 0% 2px;
    background-repeat: no-repeat;
    background-position: 50% calc(100%);
    background-image: linear-gradient(#B09032, #B09032);
    -webkit-transition: background-size .3s ease, color .3s ease;
    -moz-transition: background-size .3s ease, color .3s ease;
    -o-transition: background-size .3s ease, color .3s ease;
    transition: background-size .3s ease, color .3s ease;
}

.NavigationItem a:hover,
.NavigationItem a:focus {
    color: #B09032;
    background-size: 100% 2px;
}

.NavigationItem a:active {
    color: #E0C062;
    background-image: linear-gradient(#E0C062, #E0C062);
    background-size: 100% 2px;
    -moz-transition: unset;
    -o-transition: unset;
    transition: unset;
}

.NavigationItem a.active {
    color: #D0B052;
    background-image: linear-gradient(#D0B052, #D0B052);
    background-size: 100% 2px;
    -moz-transition: unset;
    -o-transition: unset;
    transition: unset;
}

.NavigationItem span {
    display: none;
}

@media screen and (max-width: 1100px) {
    .NavigationItem:not(:last-of-type) {
        margin-bottom: 0px;
    }

    .NavigationItem span {
        display: inline-block;
        margin: 0 1.5em;
    }

    .NavigationItem:last-of-type span {
        display: none;
    }
}

@media screen and (max-width: 600px) {
    .NavigationItem span {
        margin: 0 1.5em;
    }
}
