.About {
    padding: 1em 3em 2em 3em;
}

.About p:not(:last-of-type) {
    margin-bottom: 1em;
}

.Content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    box-sizing: border-box;
}

.Sections {
    outline-style: none;
}

.Subsection {
    margin-left: 1.5em;
}

.Subsection:first-of-type {
    margin-top: 1em;
}

.Subsection h3 {
    margin-top: 1em;
    margin-bottom: .5em;
}

.Content > div:first-of-type {
    width: calc(100% - 450px);
    box-sizing: border-box;
}

.Content > div:nth-of-type(2) {
    width: 400px;
    height: auto;
    padding: 50px;
    position: relative;
    top: 80px;
    box-sizing: border-box;
    align-self: flex-start;
    font-size: 1.1em;
    font-weight: 600;
    line-height: 1.75;
}

.Content > div:nth-of-type(2) button {
    font-size: 1em;
    font-weight: 600;
}

.Image {
    width: 100%;
    margin-bottom: 1em;
}

section {
    max-width: 900px;
}

section p, li {
    margin: 0;
}

@media screen and (max-width: 1100px) {
    .Content {
        flex-direction: column;
    }

    .Content > div:first-of-type {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        box-sizing: border-box;
    }

    .Content > div:nth-of-type(2) {
        display: none;
    }
}

@media screen and (max-width: 767px) {
    .About {
        padding: 1em 3em 1em 2em;
    }

    .Sections section:first-of-type {
        margin-top: 0;
    }

    .Sections section:not(:first-of-type) {
        margin-top: 24px;
    }

    .Subsection {
        margin-left: 1em;
    }
}

@media screen and (max-width: 600px) {
    .About {
        padding: 1em 3em 1em 1em;
    }
}
