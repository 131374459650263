.Button {
    position: relative;
    box-sizing: border-box;
    font-size: 1em;
    font-family: Arial;
    font-weight: bold;
    letter-spacing: 2px;
    border: none;
    border-radius: 10px;
    cursor: pointer;
}

.Contained {
    color: #000000;
    background-color: #B09032;
    -moz-box-shadow: 1px 1px 1px rgba(56, 56, 56, 1), -1px 1px 1px rgba(56, 56, 56, 1);
    -webkit-box-shadow: 1px 1px 1px rgba(56, 56, 56, 1), -1px 1px 1px rgba(56, 56, 56, 1);
    box-shadow: 1px 1px 1px rgba(56, 56, 56, 1), -1px 1px 1px rgba(56, 56, 56, 1);
}

.Contained:hover,
.Contained:focus,
.Contained:active {
    transform: translateY(-1px);
    -moz-box-shadow: 2px 2px 4px rgba(86, 86, 86, 1), -2px 2px 4px rgba(86, 86, 86, 1);
    -webkit-box-shadow: 2px 2px 4px rgba(86, 86, 86, 1), -2px 2px 4px rgba(86, 86, 86, 1);
    box-shadow: 2px 2px 4px rgba(86, 86, 86, 1), -2px 2px 4px rgba(86, 86, 86, 1);
}

.Contained:hover,
.Contained:focus {
    background-color: #D0B052;
}

.Contained:active {
    background-color: #E0C062;
}

.Large {
    padding: 6px 12px;
    box-sizing: border-box;
}

.Small {
    padding: 4px 8px;
    box-sizing: border-box;
}

@media screen and (max-width: 767px) {
    .Button {
        border-radius: 8px;
    }

    .Large {
        padding: 5px 10px;
    }

    .Small {
        padding: 3px 6px;
    }
}
