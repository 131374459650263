.NavigationItems {
    height: 60px;
    width: auto;
    display: flex;
    flex-flow: row;
    justify-content: space-between;
    padding: 0;
    margin: 0;
    list-style: none;
}

@media screen and (max-width: 767px) {
    .NavigationItems {
        flex-flow: column;
    }
}
