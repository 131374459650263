.Results {
    padding: 1em 3em 2em 3em;
}

.LeadParagraph {
    margin-bottom: 2em;
    font-size: 1.25em;
    max-width: 1000px;
}

.Content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.Content > div:first-of-type {
    width: calc(100% - 350px);
    max-width: 1100px;
}

.Content > div:nth-of-type(2) {
    width: 400px;
    height: auto;
    position: -webkit-sticky;
    position: sticky;
    top: 80px;
    padding: 50px;
    margin: 80px 0 80px 0;
    box-sizing: border-box;
    align-self: flex-start;
    font-size: 1.1em;
    font-weight: 600;
    line-height: 1.75;
}

.Content > div:nth-of-type(2) button {
    font-size: 1em;
    font-weight: 600;
}

.Image {
    width: 100%;
    margin-bottom: 1em;
}

.Show {
    display: block;
}

@media screen and (max-width: 1200px) {
    .Content {
        flex-direction: column;
    }

    .Content > div:first-of-type {
        width: 100%;
    }

    .Content > div:nth-of-type(2) {
        display: none;
    }
}

@media screen and (max-width: 767px) {
    .Results {
        padding: 1em 3em 1em 2em;
    }

    .LeadParagraph {
        margin-bottom: 1em;
    }
}

@media screen and (max-width: 600px) {
    .Results {
        padding: 1em 3em 1em 1em;
    }
}
