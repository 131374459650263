.Testimonial {
    max-width: 50em;
    display: flex;
    flex-direction: column;
    outline-style: none;
}

.TestimonialWrapper {
    min-height: 4.5em;
    position: relative;
    overflow: hidden;
}

.TestimonialWrapper:focus {
    background-color: red;
}

.TestimonialHeadline {
    display: block;
    margin: 0 0 1em 0;
    font-size: 1.2em;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.TestimonialBody {
    line-height: 1.5em;
}

.ButtonPanel {
    height: 1.5em;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-start;
    margin: .5em 0 1em 0;
}

.ExpandButton {
    padding: 0;
    margin: .5em 3em 0 0;
    box-sizing: border-box;
    font-size: 1.1em;
    font-weight: bold;
    letter-spacing: 1px;
    word-spacing: 1px;
    border: none;
    cursor: pointer;
    color: #0000B0;
    background-color: transparent;
}

.ExpandButton:hover,
.ExpandButton:focus {
    color: #8000C0;
}

.ExpandButton:active {
    color: #9800E0;
}

.TestimonialSource {
    display: block;
    margin: 0;
}

.HideFullTestimonial {
    max-height: 4.5em;
}

.HideFullTestimonial:after {
    content: "";
    height: 1.5em;
    width: 100%;
    position: absolute;
    left: 0;
    bottom: 0;
    text-align: right;
    background: linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1) 50%);
}

@media screen and (max-width: 600px) {
    .ButtonPanel {
        margin: 0 0 1.5em 0;
    }
}
