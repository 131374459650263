.DrawerToggle {
    width: 24px;
    height: 40px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    align-items: flex-end;
    padding: 8px 0;
    box-sizing: border-box;
    border: none;
    background-color: #04386D;
    cursor: pointer;
}

.DrawerToggle div {
    width: 100%;
    height: 2px;
    background-color: #FFFFFF;
}

.DrawerToggle:hover div,
.DrawerToggle:focus div {
    background-color: #B09032;
}

.DrawerToggle.Active div {
    background-color: #D0B052;
}

.DrawerToggle:active div {
    background-color: #E0C062;
}
