.NavigationItem {
    height: auto;
    width: auto;
    display: block;
    box-sizing: border-box;
}

.NavigationItem a {
    height: auto;
    width: auto;
    padding: 0;
    font-size: 1.2em;
    text-decoration: none;
    color: #FFFFFF;
    box-sizing: border-box;
    background-color: transparent;
}

.NavigationItem:not(:last-of-type) {
    margin-bottom: 50px;
}

.NavigationItem a:hover,
.NavigationItem a:focus {
    color: #B09032;
}

.NavigationItem a.active {
    color: #D0B052;
    border-bottom: 2px solid #D0B052;
}

.NavigationItem a:active {
    color: #E0C062;
    border-bottom: 2px solid #E0C062;
}
