.Team {
    padding: 1em 0;
    margin: 1em 0;
    box-sizing: border-box;
    outline-style: none;
}

.TeamList {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

@media screen and (max-width: 600px) {
    .Team {
        padding: 1em 0 0 0;
        margin: 0;
    }
}
