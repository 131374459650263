.QuestionsToAskEveryRealEstateAgent {
    padding: 2em 3.5em 3em 3.5em;
    box-sizing: border-box;
}

.LeadImageContainer {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: 0 0 2em 0;
}

.LeadImage {
    width: 60%;
    max-width: 600px;
}

.Contacts {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    padding: 1em 4em 0 4em;
    margin-bottom: 2em;
    box-sizing: border-box;
    border-top: 1px solid #777777;
    border-bottom: 1px solid #777777;
}

.Contacts * {
    font-size: 1.25em;
    margin-bottom: 1em;
}

.QuestionsToAskEveryRealEstateAgent h1 {
    display: block;
    font-size: 2em;
    line-height: 1.3em;
    color: #04386D;
}

.QuestionsToAskEveryRealEstateAgent h1 u {
    text-underline-offset: 5px;
}

.MainContent {
    max-width: 900px;
}

.LeadText {
    max-width: 800px;
    padding-bottom: 2em;
    margin: 2em 0 4em 2em;
    box-sizing: border-box;
    border-bottom: 1px solid #999999;
}

.LeadText p {
    font-size: 1.1em;
}

.LeadTextSignature {
    display: block;
    font-size: 1.25em;
    font-weight: 600;
    text-align: right;
    margin-right: 3em;
}

.MainContent > h2 {
    margin-top: 2em;
    margin-bottom: 0;
    font-size: 1.1em;
    line-height: 1.4em;
}

.MainContent p {
    margin-top: .5em;
}

.ListNoBullets li {
    list-style: none;
}

@media screen and (max-width: 767px) {
    .LeadText {

    }

    .Contacts {
        padding: 1em .5em 0 .5em;
    }
}
