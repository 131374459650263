.NavigationItem {
    display: block;
    box-sizing: border-box;
    margin: 0;
}

.NavigationItem:not(:last-of-type) {
    margin-bottom: 0;
    margin-right: 30px;
}

.NavigationItem a {
    height: 100%;
    width: 100%;
    padding: 0 0 .5em 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    font-size: 1.1em;
    letter-spacing: 1px;
    text-decoration: none;
    color: #FFFFFF;
    border-bottom: 4px solid transparent;
    box-sizing: border-box;
    background-color: transparent;
}


.NavigationItem a:hover,
.NavigationItem a:focus {
    color: #B09032;
}

.NavigationItem a:active {
    color: #E0C062;
}

.NavigationItem a.active {
    color: #D0B052;
    border-bottom: 4px solid #D0B052;
}

@media screen and (max-width: 767px) {
    .NavigationItem {
        height: 60px;
    }

    .NavigationItem:not(:last-of-type) {
        margin-right: 0;
        margin-bottom: 5px;
    }

    .NavigationItem a,
    .NavigationItem a:active,
    .NavigationItem a.active {
        border-bottom: none;
    }
}
