.Accordion {
    width: 100%;
    max-width: 700px;
    padding: 0;
    box-sizing: border-box;
    overflow: visible;
}

.AccordionCollapsed {
    border-bottom: 1px solid #777777;
}

.InnerAccordion {
    border: none;
}

.AccordionExpanded {
    border-bottom: 3px solid #777777;
}

.PanelToggleButton {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: .75em 1em .75em 1em;
    margin: 0;
    box-sizing: border-box;
    font-size: 1em;
    outline-style: none;
    border: none;
    cursor: pointer;
    -webkit-transition: background-color 200ms ease;
    -moz-transition: background-color 200ms ease;
    -o-transition: background-color 200ms ease;
    -ms-transition: background-color 200ms ease;
    transition: background-color 200ms ease;
}

.PanelToggleButtonIconContainer {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.PanelToggleButtonInactive {
    background-color: rgba(256, 256, 256, 1);
}

.PanelToggleButtonHovered,
.PanelToggleButtonFocused {
    background-color: rgba(176, 144, 50, .7);
}

.PanelToggleButton:active {
    color: #000000;
    background-color: rgba(208, 176, 82, .8);
}

.PanelToggleButtonActive {
    background-color: rgba(208, 176, 82, .8);
}

.PanelToggleButton span {
    display: block;
    margin-left: 1em;
    box-sizing: border-box;
    text-align: left;
}

.PanelToggleButtonInactive span {
    color: #000000;
}

.PanelToggleButtonActive span {
    color: #0000B0;
    font-weight: 600;
}

.IconPanelOpen {
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
    -ms-transition: transform .7s ease;
    -o-transition: transform .7s ease;
    -moz-transition: transform .7s ease;
    -webkit-transition: transform .7s ease;
    transition: transform .7s ease;
}

.IconPanelClosed {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    -ms-transition: transform .7s ease;
    -o-transition: transform .7s ease;
    -moz-transition: transform .7s ease;
    -webkit-transition: transform .7s ease;
    transition: transform .7s ease;
}

.Panel {
    height: auto;
    max-height: 0;
    padding-left: 1.5em;
    box-sizing: border-box;
    overflow: hidden;
    outline-style: none;
}

.PanelOpen {
    height: auto;
    max-height: 9999px;
    margin-top: .25em;
    -ms-transition: max-height .7s cubic-bezier(1, 0, 1, 0);
    -o-transition: max-height .7s cubic-bezier(1, 0, 1, 0);
    -moz-transition: max-height .7s cubic-bezier(1, 0, 1, 0);
    -webkit-transition: max-height .7s cubic-bezier(1, 0, 1, 0);
    transition: max-height .7s cubic-bezier(1, 0, 1, 0);
}

.PanelClosed {
    max-height: 0;
    -ms-transition: max-height .7s cubic-bezier(0, 1, 0, 1);
    -o-transition: max-height .7s cubic-bezier(0, 1, 0, 1);
    -moz-transition: max-height .7s cubic-bezier(0, 1, 0, 1);
    -webkit-transition: max-height .7s cubic-bezier(0, 1, 0, 1);
    transition: max-height .7s cubic-bezier(0, 1, 0, 1);
}

.PanelContent {
    padding: .5em 1em .5em .5em;
    padding: .5em 1em 1em 0;
    padding: .5em .5em 1em 0;
    box-sizing: border-box;
    overflow: hidden;
}

.PanelContent p:last-of-type {
    margin-bottom: 0;
}
