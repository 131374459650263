.StickyNavContent {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
}

.Content {
    width: calc(100% - 320px);
    max-width: 900px;
    padding: 30px 60px;
    box-sizing: border-box;
}

.LeadContent p {
    margin: 0 0 1.5em 0;
}

.SubsectionContent h4 {
    margin: 2em 0 .5em 0;
}

.SubsectionContent p {
    margin-bottom: 1.5em;
}

.Section {
    padding-top: .1em;
}

.Subsection {
    box-sizing: border-box;
}

.Subsection:not(:first-of-type) {
    margin-top: 2em;
}

.SubsectionContent {
    margin: 0 0 0 24px;
    box-sizing: border-box;
}

.Hide {
    display: none;
}

.ProviderLink {
    margin-left: 2em;
    text-align: left;
    color: #000000;
}

.ListItemParagraph span {
    margin-right: 12px;
    font-weight: 700;
}

.FileList {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    flex-wrap: wrap;
    margin: 3em 0 0 0;
    padding: 0;
    box-sizing: border-box;
}

.FileList li {
    list-style: none;
    margin: 0 4em 5em 0;
}

.File {
    width: 12em;
}

.File img {
    width: 100%;
    -moz-box-shadow: 0px 2px 3px 1px rgba(140, 140, 140, 1), 0px 0px 3px 1px rgba(140, 140, 140, 1);
    -webkit-box-shadow: 0px 2px 3px 1px rgba(140, 140, 140, 1), 0px 0px 3px 1px rgba(140, 140, 140, 1);
    box-shadow: 0px 2px 3px 1px rgba(140, 140, 140, 1), 0px 0px 3px 1px rgba(140, 140, 140, 1);
}

.FileName {
    min-height: 8ex;
    display: block;
    margin: .5em 0 .75em 0;
}

.FileLinks {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.FileLinks a {
    margin: 0 .75em 0 .75em;
    font-weight: bold;
    letter-spacing: 1px;
    color: blue;
}

.FileLinks a:hover,
.FileLinks a:focus {
    color: #8000C0;
}

.FileLinks a:active {
    color: #9800E0;
}

.Section:last-of-type {
    margin-bottom: 2em;
}

@media screen and (max-width: 1100px) {
    .Content {
        width: 100%;
    }
}

@media screen and (max-width: 767px) {
    .StickyNavContent {
        display: block;
    }

    .SideNavWrapper {
        width: 200px;
        padding: 0 .75em 0 .75em;
    }

    .Content {
        width: 100%;
        padding: 1em 3.5em;
    }

    .Section {
        margin-top: .5em;
    }

    .Subsection:not(:first-of-type) {
        margin-top: 1.5em;
    }

    .Subsection {
        margin: 0 0 0 .5em;
    }

    .SubsectionContent {
        margin: 0 0 0 12px;
    }

    .FileList {
        margin: 1em 0 0 0;
        padding-left: 20px;
    }

    .File {
        width: 14em;
    }

    .File img {
        -moz-box-shadow: 0px 2px 3px 1px rgba(140, 140, 140, 1), 0px 0px 3px 1px rgba(140, 140, 140, 1);
        -webkit-box-shadow: 0px 2px 3px 1px rgba(140, 140, 140, 1), 0px 0px 3px 1px rgba(140, 140, 140, 1);
        box-shadow: 0px 2px 3px 1px rgba(140, 140, 140, 1), 0px 0px 3px 1px rgba(140, 140, 140, 1);
    }

    .FileName {
        min-height: 6ex;
        display: block;
    }
}
