.TabItemLink {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-size: 1.25em;
    font-weight: 600;
    text-decoration: none;
    color: #0000B0;
}

.TabItemLink:hover,
.TabItemLink:focus {
    color: #8000C0;
}

.active {
    color: #9800E0;
    text-decoration: underline;
    text-decoration-thickness: .15em;
    text-underline-offset: .3em;
    text-decoration-color: #9800E0;
}

@media screen and (max-width: 767px) {
    .TabItemLink a:active,
    .TabItemLink a.active {
        text-underline-offset: .2em;
    }
}
