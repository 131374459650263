.Tabs {
    max-width: 900px;
    width: 100%;
    padding-top: 1em;
    box-sizing: border-box;
    outline-style: none;
}

.TabItemLinks {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    border-bottom: 2px solid #04386D;
    list-style: none;
}

.TabItemLinkItem {
    padding: 0;
    margin: 1em 0;
}

.TabItemContainer {
    width: 100%;
    padding: 1em;
    box-sizing: border-box;
    outline-style: none;
}

.screenReaderOnly {
    clip: rect(1px, 1px, 1px, 1px);
    clip-path: inset(50%);
    height: 1px;
    width: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    outline-style: none;
    background-color: red;
}

@media screen and (max-width: 400px) {
    .TabItemLinks {
        justify-content: space-between;
        padding: 0 .75em;
    }
}
