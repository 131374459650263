.Input {
    width: 100%;
    box-sizing: border-box;
    font-family: Georgia, Garamond, "Times New Roman", serif;
}

.LabelSpan {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    box-sizing: border-box;
    font-size: 1.2em;
    font-weight: bold;
}

label {
    display: flex;
    flex-direction: row;
    align-items: center;
    letter-spacing: 1px;
    word-spacing: 3px;
    color: #FFFFFF;
}

.RequiredField {
    margin-left: 8px;
    color: #FFFFFF;
}

.ErrorMessageContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.WarningIcon {
    height: 20px;
    margin-right: 8px;
}

.ErrorMessage {
    display: flex;
    flex-direction: row;
    align-items: center;
    color: #FFEE22;
}

input:focus::-webkit-input-placeholder {
    opacity: 0;
}

.InputElement {
    width: 100%;
    display: block;
    padding: 6px 12px;
    margin-top: 10px;
    box-sizing: border-box;
    font-family: Arial;
    font-size: 1em;
    outline: none;
    border: none;
    border-bottom: 2px solid #FFFFFF;
    color: #FFFFFF;
    background-color: transparent;
    overflow: auto;
}

.InputElement::placeholder {
    color: #FFFFFF;
    opacity: 1;
}

.InputElement:hover,
.InputElement:focus {
    border-bottom: 2px solid #FFFFFF;
    outline: 1px solid #FFFFFF;
}

.Invalid {
    border-bottom: 2px solid #FFEE22;
}

@media screen and (max-width: 767px) {
    .RequiredField {
        margin-left: 4px;
    }

    .WarningIcon {
        height: 16px;
        margin-right: 4px;
    }

    .InputElement {
        padding: 4px 8px;
        margin-top: 6px;
    }
}
