.SectionNav {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    text-decoration: none;
    background-color: transparent;
}

.SectionNav img {
    height: 30px;
    margin-right: 1em;
    box-sizing: border-box;
}

.SectionNavSpan {
    font-size: 1.25em;
    font-weight: 900;
    letter-spacing: 1px;
}

.Submenu {
    display: none;
    padding: 0;
    margin: 1.5em 0 0 0;
    list-style: none;
}

.Submenu li {
    padding: 1em 0 1em 1.5em;
    margin: 0;
}

.Submenu a {
    display: block;
    margin: 0;
    box-sizing: border-box;
    font-size: 1em;
    letter-spacing: 1px;
    text-decoration: none;
    color: #FFFFFF;
}

.SectionNavSpanHoverFocus,
.Submenu a:hover,
.Submenu a:focus {
    color: #B09032;
}

.SectionNavSpanActive,
.Submenu a.active {
    color: #D0B052;
}

.SectionNavSpanInactive {
    color: #FFFFFF;
}

.SectionNavSpanPressed,
.Submenu a:active {
    color: #E0C062;
}

.Open {
    display: block;
}

@media screen and (max-width: 767px) {
    .SectionNav img {
        height: 20px;
    }

    .Submenu li {
        padding: 1.5em 0 1.5em 1.5em;
    }
}
